/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * The category of an appliance resource, or unknown
 */
export type ResourceApplianceCategory =
  (typeof ResourceApplianceCategory)[keyof typeof ResourceApplianceCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResourceApplianceCategory = {
  unknown: "unknown",
  "category-1": "category-1",
  "category-2": "category-2",
  "category-3": "category-3",
  "category-4": "category-4",
  "category-5": "category-5",
  "category-6": "category-6",
  "category-7": "category-7",
  "category-8": "category-8",
  "category-9": "category-9",
  "category-10": "category-10",
  "category-11": "category-11",
  "category-12": "category-12",
  "category-13": "category-13",
  "category-14": "category-14",
  "category-15": "category-15",
  "category-16": "category-16",
  "category-17": "category-17",
  "category-18": "category-18",
  "category-19": "category-19",
  "category-20": "category-20",
  "category-21": "category-21",
  "category-22": "category-22",
} as const;
