/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetResources200,
  GetResourcesExternalExternalId200,
  GetResourcesId200,
  GetResourcesParams,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * Gets a single resource
 * @summary Get resource
 */
export const getResourcesId = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetResourcesId200>> => {
  return axios.get(`/resources/${id}`, options);
};

export const getGetResourcesIdQueryKey = (id: string) => {
  return [`/resources/${id}`] as const;
};

export const getGetResourcesIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getResourcesId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getResourcesId>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetResourcesIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getResourcesId>>> = ({
    signal,
  }) => getResourcesId(id, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getResourcesId>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetResourcesIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getResourcesId>>
>;
export type GetResourcesIdQueryError = AxiosError<JSONAPIErrorResponse>;

export function useGetResourcesId<
  TData = Awaited<ReturnType<typeof getResourcesId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getResourcesId>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getResourcesId>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetResourcesId<
  TData = Awaited<ReturnType<typeof getResourcesId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getResourcesId>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getResourcesId>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetResourcesId<
  TData = Awaited<ReturnType<typeof getResourcesId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getResourcesId>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get resource
 */

export function useGetResourcesId<
  TData = Awaited<ReturnType<typeof getResourcesId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getResourcesId>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetResourcesIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Gets a single resource by external id
 * @summary Get resource by external id
 */
export const getResourcesExternalExternalId = (
  externalId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetResourcesExternalExternalId200>> => {
  return axios.get(`/resources/external/${externalId}`, options);
};

export const getGetResourcesExternalExternalIdQueryKey = (
  externalId: string,
) => {
  return [`/resources/external/${externalId}`] as const;
};

export const getGetResourcesExternalExternalIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  externalId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetResourcesExternalExternalIdQueryKey(externalId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getResourcesExternalExternalId>>
  > = ({ signal }) =>
    getResourcesExternalExternalId(externalId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!externalId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetResourcesExternalExternalIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getResourcesExternalExternalId>>
>;
export type GetResourcesExternalExternalIdQueryError =
  AxiosError<JSONAPIErrorResponse>;

export function useGetResourcesExternalExternalId<
  TData = Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  externalId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetResourcesExternalExternalId<
  TData = Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  externalId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetResourcesExternalExternalId<
  TData = Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  externalId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get resource by external id
 */

export function useGetResourcesExternalExternalId<
  TData = Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  externalId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getResourcesExternalExternalId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetResourcesExternalExternalIdQueryOptions(
    externalId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Gets a list of resources
 * @summary Get resources
 */
export const getResources = (
  params?: GetResourcesParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetResources200>> => {
  return axios.get(`/resources`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetResourcesQueryKey = (params?: GetResourcesParams) => {
  return [`/resources`, ...(params ? [params] : [])] as const;
};

export const getGetResourcesQueryOptions = <
  TData = Awaited<ReturnType<typeof getResources>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetResourcesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getResources>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetResourcesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getResources>>> = ({
    signal,
  }) => getResources(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getResources>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetResourcesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getResources>>
>;
export type GetResourcesQueryError = AxiosError<JSONAPIErrorResponse>;

export function useGetResources<
  TData = Awaited<ReturnType<typeof getResources>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params: undefined | GetResourcesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getResources>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getResources>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetResources<
  TData = Awaited<ReturnType<typeof getResources>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetResourcesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getResources>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getResources>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetResources<
  TData = Awaited<ReturnType<typeof getResources>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetResourcesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getResources>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get resources
 */

export function useGetResources<
  TData = Awaited<ReturnType<typeof getResources>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetResourcesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getResources>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetResourcesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
