/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * The category of a heavy plant resource, or unknown
 */
export type ResourceHeavyPlantCategory =
  (typeof ResourceHeavyPlantCategory)[keyof typeof ResourceHeavyPlantCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResourceHeavyPlantCategory = {
  "bulldozer-d5": "bulldozer-d5",
  "bulldozer-d6": "bulldozer-d6",
  "grader-12": "grader-12",
  "grader-14": "grader-14",
  unknown: "unknown",
} as const;
