import { showToast } from "@app/design-system";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { loadImage, type MapImage } from "../utils/loadImage";
import {
  type FeatureServerJson,
  getFeatureServerSymbology,
  type AVLType,
} from "./utils";

const fallbackImageMap = {
  appliance: {
    imageId: "fallback",
    src: "/icons/vehicle-category-not-identified.png",
  },
  portable: {
    imageId: "fallback",
    src: "/icons/radio-status-not-known.png",
  },
} as const satisfies Record<AVLType, MapImage>;

interface FetchSymbologyParams {
  accessToken: string;
  url: string;
}

const fetchSymbology = async ({ accessToken, url }: FetchSymbologyParams) => {
  const response = await fetch(url, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const json = await response.json();

  return json as FeatureServerJson;
};

const useLoadAVLSymbology = ({
  accessToken,
  avlType,
  layerId,
  map,
  symbologyOverrides,
  symbologyUrl,
}: {
  accessToken: string;
  avlType: AVLType;
  layerId: string;
  map: mapboxgl.Map | maplibregl.Map;
  symbologyOverrides?: Partial<Record<string, string>>;
  symbologyUrl: string;
}) => {
  const { data: symbologyData, isError } = useQuery({
    queryFn: () => fetchSymbology({ accessToken, url: symbologyUrl }),
    queryKey: ["AVL_SYMBOLOGY", layerId],
    retry: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!symbologyData) return;

    const images = getFeatureServerSymbology(symbologyData, symbologyOverrides);

    const loadImages = async () => {
      try {
        await Promise.all(
          [fallbackImageMap[avlType], ...images].map((image) => {
            // NOTE: We add the avlType property to handle cases where appliance and portable icons have overlapping StatusCategory values
            return loadImage({
              ...image,
              imageId: `${avlType}-${image.imageId}`,
              map,
            });
          }),
        );
      } catch {
        showToast({
          id: layerId,
          message: "Please reload layer and try again",
          title: "Unable to load AVL symbology",
          variant: "error",
        });
      }
    };

    void loadImages();
  }, [map, layerId, symbologyData, symbologyOverrides, avlType]);

  useEffect(() => {
    if (isError) {
      showToast({
        id: "avl-symbology-load-error",
        message: "Please reload layer and try again",
        title: "Unable to load AVL symbology",
        variant: "error",
      });
    }
  }, [isError, layerId]);

  return symbologyData;
};

export default useLoadAVLSymbology;
