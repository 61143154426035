// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface AsyncViewProps<T extends {} = {}> {
  children?: (data: T) => React.ReactNode;
  data: T | undefined;
  errorState: React.ReactNode;
  isError: boolean;
  loadingState: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
const AsyncView = <T extends {} = {}>({
  children,
  data,
  errorState,
  isError,
  loadingState,
}: AsyncViewProps<T>) => {
  if (isError) {
    return <>{errorState}</>;
  }

  if (!data) {
    return <>{loadingState}</>;
  }

  return <>{children?.(data)}</>;
};

export default AsyncView;
