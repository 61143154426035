import {
  AVLStatusAlerted,
  AVLStatusAtStation,
  AVLStatusAvailable,
  AVLStatusNotAvailable,
  AVLStatusNotKnown,
  AVLStatusOnScene,
  AVLStatusProceeding,
  AVLStatusResponding,
  AVLStatusUndispatchable,
  type FormControlOption,
} from "@app/design-system";
import { ResourceApplianceStatus } from "../../.rest-hooks/types/resources.yml/resourceApplianceStatus";

const resourceApplianceStatuses = Object.values(
  ResourceApplianceStatus,
).toSorted();

export const isResourcesApplianceStatus = (
  value: string,
): value is ResourceApplianceStatus => {
  return resourceApplianceStatuses.includes(value as ResourceApplianceStatus);
};

export const applianceStatusLabels: Record<ResourceApplianceStatus, string> = {
  alerted: "Alerted",
  "at-station": "At station",
  available: "Available",
  dispatchable: "Dispatchable",
  "not-available": "Not available",
  "not-known": "Not known",
  "on-scene": "On scene",
  proceeding: "Proceeding",
  responding: "Responding",
  undispatchable: "Undispatchable",
} as const;

export const applianceStatusOptions: FormControlOption<ResourceApplianceStatus>[] =
  resourceApplianceStatuses.map((status) => ({
    label: applianceStatusLabels[status],
    value: status,
  }));

export const applianceStatusIconMap: Record<
  ResourceApplianceStatus,
  ReactSVGComponent
> = {
  alerted: AVLStatusAlerted,
  "at-station": AVLStatusAtStation,
  available: AVLStatusAvailable,
  dispatchable: AVLStatusAtStation,
  "not-available": AVLStatusNotAvailable,
  "not-known": AVLStatusNotKnown,
  "on-scene": AVLStatusOnScene,
  proceeding: AVLStatusProceeding,
  responding: AVLStatusResponding,
  undispatchable: AVLStatusUndispatchable,
} as const;

export function getApplianceStatusIcon(
  applianceStatus: ResourceApplianceStatus | undefined,
): ReactSVGComponent {
  if (!applianceStatus) return applianceStatusIconMap["not-known"];

  if (applianceStatus in applianceStatusIconMap) {
    return applianceStatusIconMap[applianceStatus];
  }

  return applianceStatusIconMap["not-known"];
}

export function getApplianceStatusLabel(
  applianceStatus: ResourceApplianceStatus | undefined,
) {
  if (!applianceStatus || !(applianceStatus in applianceStatusLabels))
    return "Not known";

  if (applianceStatus in applianceStatusLabels) {
    return applianceStatusLabels[applianceStatus];
  }

  return applianceStatus;
}
