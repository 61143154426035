import {
  AVLStatusAvailable,
  AVLStatusNotAvailable,
  AVLStatusNotKnown,
  AVLStatusOnScene,
  AVLStatusProceeding,
  type FormControlOption,
} from "@app/design-system";
import { ResourceAircraftStatus } from "../../.rest-hooks/types/resources.yml/resourceAircraftStatus";

const aircraftStatuses = Object.values(ResourceAircraftStatus);

export const isAircraftStatus = (
  status: string,
): status is ResourceAircraftStatus => {
  return aircraftStatuses.includes(status as ResourceAircraftStatus);
};

export const aircraftStatusGroups = [
  "available",
  "dispatched",
  "limited",
  "planned-dispatch",
  "standby",
  "undispatchable",
  "not-available",
  "not-known",
] as const;

export type AircraftStatusGroup = (typeof aircraftStatusGroups)[number];

export const isAircraftStatusGroup = (
  statusGroup: string,
): statusGroup is AircraftStatusGroup => {
  return aircraftStatusGroups.includes(statusGroup as AircraftStatusGroup);
};

export const aircraftStatusGroupDictionary: Record<
  ResourceAircraftStatus,
  AircraftStatusGroup
> = {
  "planned-dispatch": "planned-dispatch",
  "standby-amended": "standby",
  "standby-temp-asset": "standby",
  "unserviceable-airdesk": "undispatchable",
  "unserviceable-restricted": "undispatchable",
  "unserviceable-standby": "undispatchable",
  "unserviceable-stood-down": "undispatchable",
  available: "available",
  deployed: "dispatched",
  limited: "limited",
  standby: "standby",
  unavailable: "not-available",
  unserviceable: "undispatchable",
  "not-known": "not-known",
};

export const aircraftStatusGroupMap: Record<
  AircraftStatusGroup,
  ResourceAircraftStatus[]
> = {
  "not-available": ["unavailable"],
  "planned-dispatch": ["planned-dispatch"],
  available: ["available"],
  dispatched: ["deployed"],
  limited: ["limited"],
  standby: ["standby", "standby-amended", "standby-temp-asset"],
  undispatchable: [
    "unserviceable",
    "unserviceable-airdesk",
    "unserviceable-restricted",
    "unserviceable-standby",
    "unserviceable-stood-down",
  ],
  "not-known": ["not-known"],
};

export const aircraftStatusGroupLabels: Record<AircraftStatusGroup, string> = {
  "not-available": "Not available",
  "planned-dispatch": "Planned dispatch",
  available: "Available",
  dispatched: "Dispatched",
  limited: "Limited",
  standby: "Standby",
  undispatchable: "Undispatchable",
  "not-known": "Not known",
};

export const aircraftStatusLabels: Record<ResourceAircraftStatus, string> = {
  "planned-dispatch": "Planned dispatch",
  "standby-amended": "Standby (amended)",
  "standby-temp-asset": "Standby (temp asset)",
  available: "Available",
  "unserviceable-airdesk": "Unserviceable (airdesk)",
  "unserviceable-restricted": "Unserviceable (restricted)",
  "unserviceable-standby": "Unserviceable (standby)",
  "unserviceable-stood-down": "Unserviceable (stood down)",
  deployed: "Deployed",
  limited: "Limited",
  standby: "Standby",
  unavailable: "Unavailable",
  unserviceable: "Unserviceable",
  "not-known": "Not known",
};

export function getAircraftStatusGroupLabel(
  status: ResourceAircraftStatus | undefined,
) {
  if (!status) return "Not known";

  if (isAircraftStatus(status)) {
    const statusGroup = aircraftStatusGroupDictionary[status];
    return aircraftStatusGroupLabels[statusGroup];
  }

  return status;
}

export const aircraftStatusIconMap: Record<
  ResourceAircraftStatus,
  ReactSVGComponent
> = {
  available: AVLStatusAvailable,
  deployed: AVLStatusOnScene,
  limited: AVLStatusAvailable,
  "planned-dispatch": AVLStatusProceeding,
  standby: AVLStatusProceeding,
  "standby-amended": AVLStatusProceeding,
  "standby-temp-asset": AVLStatusProceeding,
  unavailable: AVLStatusNotAvailable,
  unserviceable: AVLStatusNotKnown,
  "unserviceable-airdesk": AVLStatusNotKnown,
  "unserviceable-restricted": AVLStatusNotKnown,
  "unserviceable-standby": AVLStatusNotKnown,
  "unserviceable-stood-down": AVLStatusNotKnown,
  "not-known": AVLStatusNotKnown,
} as const;

export function getAircraftStatusIcon(
  aircraftStatus: ResourceAircraftStatus | undefined,
): ReactSVGComponent {
  if (!aircraftStatus) return aircraftStatusIconMap.unserviceable;

  if (aircraftStatus in aircraftStatusIconMap) {
    return aircraftStatusIconMap[aircraftStatus];
  }

  return aircraftStatusIconMap.unserviceable;
}

export const aircraftStatusOptions: FormControlOption<ResourceAircraftStatus>[] =
  aircraftStatuses.map((aircraftStatus) => ({
    label: aircraftStatusLabels[aircraftStatus],
    value: aircraftStatus,
  }));

export const aircraftStatusGroupOptions: FormControlOption<AircraftStatusGroup>[] =
  aircraftStatusGroups.map((aircraftStatusGroup) => ({
    label: aircraftStatusGroupLabels[aircraftStatusGroup],
    value: aircraftStatusGroup,
  }));
