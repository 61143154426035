import { FieldGrid, media } from "@app/design-system";
import styled from "styled-components";
import type { ResourceAppliance } from "../../../../.rest-hooks/types/resources.yml/resourceAppliance";
import { applianceCategoryLabels } from "../../../config/applianceCategory";
import { EMDASH } from "../../../lib/strings";
import IncidentName from "../../ui/IncidentName/IncidentName";
import CopyableText from "../../ui/MapRail/CoordinateConversionDrawer/CopyableText";
import ApplianceStatusIndicator from "../ResourceStatusIndicator/ApplianceStatusIndicator";
import ApplianceAssignmentDateRange from "./ApplianceAssignmentDateRange";
import ApplianceCurrentLocation from "./ApplianceCurrentLocation";

const StyledApplianceActivitySummary = styled.div`
  display: grid;
  gap: 1.5rem;
  align-items: start;

  @media ${media.lg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const StyledColumn = styled.div`
  display: grid;
  row-gap: 1rem;
`;

export interface ApplianceActivitySummaryProps {
  appliance: ResourceAppliance;
}

const ApplianceActivitySummary = ({
  appliance,
}: ApplianceActivitySummaryProps) => {
  return (
    <StyledApplianceActivitySummary>
      <StyledColumn>
        <FieldGrid>
          <FieldGrid.Item label="Status:">
            <ApplianceStatusIndicator status={appliance.attributes.status} />
          </FieldGrid.Item>
        </FieldGrid>
        <FieldGrid>
          {appliance.attributes.assignment && (
            <>
              <FieldGrid.Item label="Assigned to:">
                <IncidentName
                  incidentId={appliance.attributes.assignment.incidentId}
                />
              </FieldGrid.Item>
              <FieldGrid.Item label="Current deployment:">
                <ApplianceAssignmentDateRange
                  assignment={appliance.attributes.assignment}
                />
              </FieldGrid.Item>
            </>
          )}
          <FieldGrid.Item label="Radio ID:">
            {appliance.attributes.currentLocation?.radioId || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Device type:">
            {appliance.attributes.currentLocation?.deviceType || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Emergency:">
            {appliance.attributes.emergency ? "Yes" : "No"}
          </FieldGrid.Item>
        </FieldGrid>
        <FieldGrid>
          <FieldGrid.Item label="Category:">
            {applianceCategoryLabels[appliance.attributes.category] || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="BF0:">
            {appliance.attributes.externalId ? (
              <CopyableText textToCopy={appliance.attributes.externalId}>
                {appliance.attributes.externalId}
              </CopyableText>
            ) : (
              EMDASH
            )}
          </FieldGrid.Item>
          <FieldGrid.Item label="Description:">
            {appliance.attributes.description || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Make:">
            {appliance.attributes.make || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Model:">
            {appliance.attributes.model || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Year:">
            {appliance.attributes.year || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Seating capacity:">
            {appliance.attributes.seatingCapacity ?? EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="License no.:">
            {appliance.attributes.licenceNumber || EMDASH}
          </FieldGrid.Item>
        </FieldGrid>
        <FieldGrid>
          <FieldGrid.Item label="Home location:">
            {appliance.attributes.homeLocation.name || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Private:">
            {appliance.attributes.homeLocation.isPrivate ? "Yes" : "No"}
          </FieldGrid.Item>
        </FieldGrid>
      </StyledColumn>
      <StyledColumn>
        <ApplianceCurrentLocation appliance={appliance} />
      </StyledColumn>
    </StyledApplianceActivitySummary>
  );
};

export default ApplianceActivitySummary;
