import type { UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";
import type { AsyncValue } from "../lib/asyncValue";

interface UseQueryAsyncValueParams<TData> {
  query: UseQueryResult<TData>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
const useQueryAsyncValue = <TData extends {}>({
  query,
}: UseQueryAsyncValueParams<TData>): AsyncValue<TData> => {
  const { data, isError, isPending } = query;

  const asyncValue = useMemo(() => {
    return {
      data,
      isError,
      isLoading: isPending,
    };
  }, [data, isError, isPending]);

  return asyncValue;
};

export default useQueryAsyncValue;
