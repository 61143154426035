/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * Statuses an appliance resource may be in
 */
export type ResourceApplianceStatus =
  (typeof ResourceApplianceStatus)[keyof typeof ResourceApplianceStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResourceApplianceStatus = {
  "at-station": "at-station",
  available: "available",
  "not-available": "not-available",
  dispatchable: "dispatchable",
  undispatchable: "undispatchable",
  responding: "responding",
  "on-scene": "on-scene",
  proceeding: "proceeding",
  alerted: "alerted",
  "not-known": "not-known",
} as const;
